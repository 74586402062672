import "./Background.css"

export const Background = () => {
    return (
        <section className="wrapper">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </section>
    )
}